// Variable overrides
$colors: (
  primary: #000000,
) !default;
$font-family-base: "Open Sauce One", "Nunito Sans", -apple-system, sans-serif;
$font-size-base: 0.875rem;
$border-radius: 0.5rem;
$border-radius-lg: 0.5rem;
$sidebar-nav-title-padding-y: 0.5rem !default;
$sidebar-nav-link-padding-y: 0.5rem !default;
