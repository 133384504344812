// If you want to override variables do it here
@import "./_variables";

// Import CoreUI styles
@import "./_custom_coreui";
// If you want to add something do it here
@import "~antd/dist/antd.css";
@import "~@coreui/coreui/scss/coreui.scss";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-daterangepicker/daterangepicker.css";
@import "~swiper/swiper.scss";
@import "./tw-main.css";
@import "~animate.css/animate.css";
@import "./_animation";
